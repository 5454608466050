@import './variables';

.main {
  display: flex;
  margin-bottom: 24px;

  > * + * {
    margin: 0 0 0 24px;
  }
}

.triggers {
  flex-grow: 1;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  padding: 24px;
  border-radius: 12px;
  background: url("data:image/svg+xml,%3Csvg width='904' height='96' viewBox='0 0 904 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H12C5.37258 0 0 5.37258 0 12V41L4.74724 50.9692C4.91104 51.3132 5.18508 51.5925 5.52583 51.7629C7.13857 52.5693 6.56476 55 4.76166 55H4.65178C4.2316 55 3.8257 54.8475 3.50949 54.5708L0 51.5V84C0 90.6274 5.37258 96 12 96H89.5C90.2153 93.3178 93.8464 92.8995 95.1527 95.3489L95.5 96H315C315.475 93.6235 318.71 93.2348 319.734 95.431L320 96H466.5L466.561 95.7369C467.26 92.7409 471.593 92.9505 472 96H642L647.478 89.2177C648.534 87.9107 650.617 89.0251 650.116 90.6287C650.04 90.8723 650.026 91.1312 650.076 91.3815L651 96H758L759.382 91.3919C759.457 91.1441 759.415 90.876 759.268 90.6629C758.68 89.807 759.841 88.841 760.575 89.5755L767 96H857C857.557 93.2162 861.431 92.946 862.369 95.6255L862.5 96H892C898.627 96 904 90.6274 904 84V12C904 5.37258 898.627 0 892 0H807V8.08117C807 8.96042 805.735 9.09448 805.55 8.23475C805.518 8.08224 805.437 7.94412 805.321 7.8405L796.5 0H678.5C677.171 3.54298 672.498 4.30562 670.112 1.36888L669 0H591.5L591.398 0.358314C590.793 2.47462 587.709 2.19108 587.5 0H514C515.278 3.19468 511.409 5.95615 508.803 3.70953L504.5 0H386.5L385.627 1.55882C384.189 4.12711 380.265 2.9342 380.5 0H240L236.977 2.64505C236.411 3.13997 236.281 3.96883 236.668 4.6133C237.586 6.14295 235.623 7.76047 234.297 6.56713L227 0H158L157.787 0.531357C156.668 3.33102 152.591 2.95677 152 0H26C25.186 2.44194 21.9196 2.87938 20.4918 0.737654L20 0Z' fill='%23212131'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;
}

.triggersBtn {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;
  text-transform: uppercase;
  color: $white;
  opacity: 0.5;
  border-radius: 6px;
  transition: background $trans, opacity $trans;
  cursor: pointer;

  &[aria-selected='true'] {
    opacity: 1;
    background: #2a2a3c;
  }

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.walletBalances {
  display: flex;

  > *:first-child {
    margin-right: 16px;
  }
}

.user,
.wallet {
  border-radius: 12px;
  width: 264px;
  padding: 29px 35px 29px 88px;
  min-height: 96px;
  position: relative;
  background: url("data:image/svg+xml,%3Csvg width='264' height='96' viewBox='0 0 264 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H12C5.37258 0 0 5.37258 0 12V17L4.74724 26.9692C4.91104 27.3132 5.18508 27.5925 5.52583 27.7629C7.13857 28.5693 6.56476 31 4.76166 31H4.65178C4.2316 31 3.8257 30.8475 3.50949 30.5708L0 27.5V84C0 90.6274 5.37258 96 12 96H58L58.7147 94.8883C59.8243 93.1622 62.5 93.9481 62.5 96H152.5C152.5 92.6075 156.807 91.1535 158.863 93.852L160.5 96H245.5C245.165 93.4857 248.471 91.5863 250.601 92.9632C253.178 94.6286 255.581 96 256 96C260.418 96 264 92.4183 264 88V28.5C260.773 25.2734 260.176 20.2567 262.556 16.363L264 14V12C264 5.37258 258.627 0 252 0H220C218.622 2.75661 214.824 3.09903 212.975 0.633436L212.5 0H178L184.582 5.642C184.841 5.86371 184.963 6.20605 184.902 6.54123L184.877 6.67566C184.671 7.81003 183 7.65936 183 6.5064C183 6.19003 182.842 5.89459 182.579 5.7191L174 0H100.5L98.5219 2.24788C96.9259 4.06149 94.027 2.21126 95 0H26C25.186 2.44194 21.9196 2.87938 20.4918 0.737654L20 0Z' fill='%23212131'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;
}

.wallet {
  width: 360px;
}

.userAvatar {
  position: absolute;
  left: 16px;
  top: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  background: #2d2c3f;
}

.userName {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 100%;
  color: $white;
}

.userNameEdit {
  margin-left: 8px;
  vertical-align: middle;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.userAddress {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 115%;
  color: $grey;
}

.wallet {
  padding: 16px;
}

.walletBalance {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 1.8rem;
  color: $gold;

  > span {
    vertical-align: middle;
    transform: translateY(-2px);
    margin-right: 8px !important;
  }
}

.walletManage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  > * {
    justify-content: space-between;
    padding: 0px 12px;
  }
}

.filter {
  margin-bottom: 24px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 12px;
  background: url("data:image/svg+xml,%3Csvg width='1480' height='56' viewBox='0 0 1480 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H12C5.37258 0 0 5.37258 0 12V41L4.74724 50.9692C4.91104 51.3132 5.18508 51.5925 5.52583 51.7629C7.13857 52.5693 6.56476 55 4.76166 55H4.65178C4.2316 55 3.8257 54.8475 3.50949 54.5708L0 51.5C0 53.9853 2.01472 56 4.5 56H89.5C90.2153 53.3178 93.8464 52.8995 95.1527 55.3489L95.5 56H315C315.475 53.6235 318.71 53.2348 319.734 55.431L320 56H466.5L466.561 55.7369C467.26 52.7409 471.593 52.9505 472 56H642L647.478 49.2177C648.534 47.9107 650.617 49.0251 650.116 50.6287C650.04 50.8723 650.026 51.1312 650.076 51.3815L651 56H1334L1335.38 51.3919C1335.46 51.1441 1335.41 50.876 1335.27 50.6629C1334.68 49.807 1335.84 48.841 1336.58 49.5755L1343 56H1433C1433.56 53.2163 1437.43 52.946 1438.37 55.6255L1438.5 56H1468C1474.63 56 1480 50.6274 1480 44V12C1480 5.37258 1474.63 0 1468 0H1383V8.08117C1383 8.96042 1381.73 9.09448 1381.55 8.23475C1381.52 8.08224 1381.44 7.94412 1381.32 7.8405L1372.5 0H678.5C677.171 3.54298 672.498 4.30562 670.112 1.36888L669 0H591.5L591.398 0.358314C590.793 2.47462 587.709 2.19108 587.5 0H514C515.278 3.19468 511.409 5.95615 508.803 3.70953L504.5 0H386.5L385.627 1.55882C384.189 4.12711 380.265 2.9342 380.5 0H240L236.977 2.64505C236.411 3.13997 236.281 3.96883 236.668 4.6133C237.586 6.14295 235.623 7.76047 234.297 6.56713L227 0H158L157.787 0.531357C156.668 3.33102 152.591 2.95677 152 0H26C25.186 2.44194 21.9196 2.87938 20.4918 0.737654L20 0Z' fill='%23212131'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;

  > * + * {
    margin-left: 6px;
  }
}

.filterSelect {
  flex: 1;
}

.filterButton {
  flex-shrink: 0;
  width: 120px;

  &:nth-of-type(1) {
    margin-left: 16px !important;
  }
}

.tokens {
  display: grid;
  align-content: flex-start;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, 210px);
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;
}

.token {
  transition: transform $trans;
  display: block;
  will-change: transform;
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    transform: scale(1.01);
  }
}

.affiliateWrapper {
  margin-bottom: 24px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  border-radius: 12px;
  background: url("data:image/svg+xml,%3Csvg width='1480' height='56' viewBox='0 0 1480 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H12C5.37258 0 0 5.37258 0 12V41L4.74724 50.9692C4.91104 51.3132 5.18508 51.5925 5.52583 51.7629C7.13857 52.5693 6.56476 55 4.76166 55H4.65178C4.2316 55 3.8257 54.8475 3.50949 54.5708L0 51.5C0 53.9853 2.01472 56 4.5 56H89.5C90.2153 53.3178 93.8464 52.8995 95.1527 55.3489L95.5 56H315C315.475 53.6235 318.71 53.2348 319.734 55.431L320 56H466.5L466.561 55.7369C467.26 52.7409 471.593 52.9505 472 56H642L647.478 49.2177C648.534 47.9107 650.617 49.0251 650.116 50.6287C650.04 50.8723 650.026 51.1312 650.076 51.3815L651 56H1334L1335.38 51.3919C1335.46 51.1441 1335.41 50.876 1335.27 50.6629C1334.68 49.807 1335.84 48.841 1336.58 49.5755L1343 56H1433C1433.56 53.2163 1437.43 52.946 1438.37 55.6255L1438.5 56H1468C1474.63 56 1480 50.6274 1480 44V12C1480 5.37258 1474.63 0 1468 0H1383V8.08117C1383 8.96042 1381.73 9.09448 1381.55 8.23475C1381.52 8.08224 1381.44 7.94412 1381.32 7.8405L1372.5 0H678.5C677.171 3.54298 672.498 4.30562 670.112 1.36888L669 0H591.5L591.398 0.358314C590.793 2.47462 587.709 2.19108 587.5 0H514C515.278 3.19468 511.409 5.95615 508.803 3.70953L504.5 0H386.5L385.627 1.55882C384.189 4.12711 380.265 2.9342 380.5 0H240L236.977 2.64505C236.411 3.13997 236.281 3.96883 236.668 4.6133C237.586 6.14295 235.623 7.76047 234.297 6.56713L227 0H158L157.787 0.531357C156.668 3.33102 152.591 2.95677 152 0H26C25.186 2.44194 21.9196 2.87938 20.4918 0.737654L20 0Z' fill='%23212131'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;

  > * + * {
    margin-left: 6px;
  }
}

.form {
  padding-left: 8px;
  width: 100%;
  height: 100%;

  input {
    height: 100%;
    width: 100%;
  }
}

.formInput {
  min-width: 1px;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: none;
  background: none;
  font-size: 1.4rem;
  line-height: 150%;
  color: $white;

  &::placeholder {
    color: $grey;
  }

  &:focus {
    outline: none;
  }
}

.affiliateButtons {
  display: flex;
  gap: 10px;  
}