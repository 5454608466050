@import './variables';

.wrapper {
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
  }

  a {
    height: 10px;
    cursor: pointer;
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #181726;
  border-radius: 12px;
  min-height: 40px;

  > * {
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.arrow {
  padding-top: 2px;
}

.link,
.dots {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 115%;
  color: $white;
  opacity: 0.5;
  transition: opacity $trans;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
}

.activeLink {
  color: #65d474;
  opacity: 1;
}

.dots {
  pointer-events: none;

  &:hover {
    opacity: 0.5;
  }
}
