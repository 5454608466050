@import './variables';

  .label {
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #212031;
    border: 1px solid #3f3d51;
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    color: $white;
    padding-top: 1px;
  }