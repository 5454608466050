/** Variables */

.wrapper,
.ReactModalPortal {
  --white: #ffffff;
  --secondary: #555568;
  --grey: #818196;
  --gold: #fbe375;
  --green: #4dccb2;
  --red: #ff002e;
  --default-transition-speed: 200ms;
  --default-transition-timing-function: ease-in-out;
}

$white: var(--white);
$gold: var(--gold);
$red: var(--red);
$green: var(--green);
$grey: var(--grey);
$secondary: var(--secondary);
$trans: var(--default-transition-speed)
  var(--default-transition-timing-function);
