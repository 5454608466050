@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Raleway:wght@700&display=swap');
@import './variables';

/** Basic styles */
*,
*:before,
*:after {
  box-sizing: border-box;
  word-break: break-word;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

input {
  text-overflow: ellipsis;
}

button {
  font: inherit;
  cursor: pointer;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

html,
body {
  font: 10px/1.15 'Poppins', sans-serif;
  color: $white;
  background: #100f21 url('../public/bg.png') center top / 1920px auto no-repeat;
  background-attachment: fixed;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  overflow: hidden scroll;
  min-width: 320px;
  max-width: 100vw;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  padding: 102px 0 0 360px;
}

.wrapper-main {
  padding: 40px 32px 40px 20px;
  width: 100%;
}

/** Buttons */

button {
  cursor: pointer;

  &.unstyled {
    border: none;
    padding: 0;
    background: none;
    text-align: left;
  }
}

.btn {
  text-decoration: none;
  padding: 0;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-with-background {
  text-decoration: none;
  padding: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

// >> button sizes
.btn {
  &--small {
    height: 32px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 133%;
  }

  &--default {
    height: 40px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 115%;

    &.btn--square {
      width: 40px;
    }
  }

  &--large {
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 115%;

    &.btn--square {
      width: 48px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

// >> btn visual styles
.btn {
  white-space: nowrap;
  transition: filter $trans;
  will-change: filter;

  &:hover:not(:disabled) {
    filter: brightness(0.8);
  }

  &--green {
    background: radial-gradient(50% 100% at 50% 100%, #4ed55b 0%, #2e873c 100%);
    border: 1px solid #65d574;
    color: $white;

    svg {
      fill: $white;
    }
  }

  &--red {
    background: radial-gradient(50% 100% at 50% 100%, #ec5858 0%, #ed2727 100%);
    border: 1px solid #ef4444;
    color: $white;

    svg {
      fill: $white;
    }
  }

  &--blue {
    background: radial-gradient(50% 100% at 50% 100%, #4e9cd5 0%, #2e6787 100%);
    border: 1px solid #65acd5;
    color: $white;

    svg {
      fill: $white;
    }
  }

  &--dark {
    background: radial-gradient(50% 100% at 50% 100%, #28283d 0%, #202030 100%);
    border: 1px solid #2d2d43;
    color: rgba(white, 0.5);
    transition: color $trans;

    svg {
      fill: rgba(white, 0.5);
    }

    &:hover:not(:disabled) {
      filter: none;
      color: $white;

      svg {
        fill: $white;
      }
    }
  }

  &--neutral {
    background: #212031;
    color: rgba(white, 0.5);
    transition: color $trans;

    &:hover:not(:disabled) {
      color: $white;
      filter: none;
    }
  }
}

/** Headings */
.heading {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.16;
  text-align: center;
  text-transform: uppercase;
  color: #fbe375;
}

/** custom input */
.custom-input {
  font: inherit;
  appearance: none;
  -webkit-appearance: none;
  min-width: 1px;
  width: unset;
  border-radius: 0;
  height: 48px;
  background: transparent;
  padding: 0px 16px;
  background: radial-gradient(50% 100% at 50% 100%, #28283d 0%, #202030 100%);
  border: 1px solid #2d2d43;
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 115%;
  color: $white;

  &--no-styling {
    background: #212031;
    border: none;
  }

  &--search {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.25' d='M9.97401 8.68408C9.57692 8.30023 8.94385 8.31097 8.56 8.70806C8.17615 9.10514 8.18689 9.73822 8.58398 10.1221L9.97401 8.68408ZM12.305 13.719C12.7021 14.1028 13.3351 14.0921 13.719 13.695C14.1028 13.2979 14.0921 12.6649 13.695 12.281L12.305 13.719ZM5.9674 11.6035C9.23086 11.6035 11.9348 9.03767 11.9348 5.80176H9.93479C9.93479 7.86971 8.19077 9.60351 5.9674 9.60351V11.6035ZM11.9348 5.80176C11.9348 2.56584 9.23086 0 5.9674 0V2C8.19077 2 9.93479 3.7338 9.93479 5.80176H11.9348ZM5.9674 0C2.70393 0 0 2.56584 0 5.80176H2C2 3.7338 3.74403 2 5.9674 2V0ZM0 5.80176C0 9.03767 2.70393 11.6035 5.9674 11.6035V9.60351C3.74403 9.60351 2 7.86971 2 5.80176H0ZM8.58398 10.1221L12.305 13.719L13.695 12.281L9.97401 8.68408L8.58398 10.1221Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 16px) center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
  }

  &::placeholder {
    color: rgba(white, 0.25);
  }

  &.h40 {
    border-radius: 8px;
    height: 40px;
  }

  &:focus {
    outline: none;
  }
}

/** Custom select */
.custom-select {
  .custom-select {
    &__control {
      background: radial-gradient(
        50% 100% at 50% 100%,
        #28283d 0%,
        #202030 100%
      );
      border: 1px solid #2d2d43;
      border-radius: 12px;
      min-height: 48px;
      cursor: pointer;
      white-space: nowrap;

      &--menu-is-open,
      &--is-focused {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #2d2d43 !important;
      }

      &.custom-select__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .custom-select__indicator {
          transform: scale(1, -1);
        }
      }

      &:hover {
        border: 1px solid #2d2d43;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      opacity: 0.25;
    }

    &__value-container {
      padding: 2px 16px;
    }

    &__single-value,
    &__placeholder {
      color: $white;
      font-weight: 400;
      font-size: 1.4rem;
    }

    &__placeholder {
      opacity: 0.25;
    }

    &__menu {
      top: 100%;
      margin: 0;
      background: #212031;
      border-radius: 0px 0px 12px 12px;
      z-index: 11;
    }

    &__option {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 115%;
      color: rgba(white, 0.25);
      background: none !important;
      padding: 6px 16px;
      cursor: pointer;
      transition: color $trans, background $trans;

      &--is-selected {
        color: $white;
        background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L4 6L1.5 3.5' stroke='%237CFF8C' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A")
          calc(100% - 16px) center / 10px auto no-repeat !important;
      }

      &:hover {
        color: $white;
      }
    }
  }

  &--no-styling .custom-select {
    &__control {
      background: #212031;
      border: none;
    }
  }

  &.h40 .custom-select {
    &__control {
      min-height: 40px;
      border-radius: 8px;

      &.custom-select__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

/** ctable */
.ctable {
  overflow: auto hidden;
  padding-bottom: 8px;

  &-row {
    display: grid;
    align-items: center;
  }

  &-head .ctable__item {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 150%;
    text-transform: uppercase;
    color: $grey;
  }

  &-scroller {
    min-width: 1350px;
  }
}

/**  input module */
.input-module {
  &__label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 133%;
    color: $grey;
    min-height: 16px;
  }

  &-content {
    padding: 0;
    margin: 0;

    > input {
      width: 100%;
    }

    &.flex-btns {
      display: flex;

      > * {
        flex: 1;

        + * {
          margin-left: 12px;
        }
      }
    }

    &.checkbox-row {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 135%;
      color: $grey;

      input {
        margin-right: 8px;
      }
    }
  }
}

/** custom-checkbox */
.custom-checkbox {
  flex-shrink: 0;
  width: 24px;
  max-width: 24px;
  height: 24px;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #212031;
    border-radius: 6px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4.5L3.47 8L10 1.41L8.6 0L3.47 5.17L1.4 3.09L0 4.5Z' fill='white'/%3E%3C/svg%3E%0A")
      center center / 100% 100% no-repeat;
    display: none;
  }

  &:checked:after {
    display: block;
  }
}

/** Modals */
// animating reactModal
.ReactModal {
  &__Overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 120px 24px;
    z-index: 101;
    opacity: 0;
    overflow: hidden auto;
    background: rgba(#100f21, 0.96) !important;
    transition: opacity $trans;

    @media (max-width: 767px) {
      padding: 75px 20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    transform: translateY(-75px);
    opacity: 0;
    outline: none;
    transition: transform $trans, opacity $trans;

    &.mobileMenu {
      transform: translateX(280px);
    }

    &--after-open {
      opacity: 1;
      transform: translateY(0px) !important;
    }

    &--before-close {
      opacity: 0;
      transform: translateY(-75px);

      &.mobileMenu {
        transform: translateX(280px);
      }
    }
  }
}

// default styles for modal
.dfkModal {
  background: #181726;
  border-radius: 12px;
  width: 560px;
  margin: 0 auto;
  max-width: 100%;
  padding: 24px;
  position: relative;

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    opacity: 0.25;
    transition: opacity $trans;
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.98924 6L11.9638 0.241104C12.0472 0.145399 11.9771 0 11.8482 0H10.336C10.2469 0 10.1616 0.0386502 10.1029 0.104908L6 4.85521L1.89714 0.104908C1.84028 0.0386502 1.755 0 1.66404 0H0.151759C0.022893 0 -0.0472254 0.145399 0.0361585 0.241104L5.01076 6L0.0361585 11.7589C0.0174797 11.7802 0.00549658 11.8063 0.00163129 11.8341C-0.00223399 11.8618 0.00218092 11.8901 0.0143523 11.9154C0.0265238 11.9408 0.0459405 11.9623 0.0702967 11.9773C0.094653 11.9923 0.122926 12.0002 0.151759 12H1.66404C1.75311 12 1.83839 11.9613 1.89714 11.8951L6 7.14478L10.1029 11.8951C10.1597 11.9613 10.245 12 10.336 12H11.8482C11.9771 12 12.0472 11.8546 11.9638 11.7589L6.98924 6Z' fill='white'/%3E%3C/svg%3E%0A")
      center center / 100% 100% no-repeat;

    &:hover {
      opacity: 1;
    }
  }

  &Title {
    font-size: 1.8rem;
    line-height: 120%;
    font-weight: 600;
  }
}

/** Tooltip */
.dfkTooltip.__react_component_tooltip {
  padding: 8px 7px;
  background: rgba(black, 0.95);
  border-radius: 6px;
  text-align: center;

  span {
    max-width: 170px;
    overflow: hidden;
    display: block;
    max-width: 200px;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 130%;
    color: $white;
  }

  &:before {
    display: none;
  }

  &:after {
    width: 12px;
    height: 6px;
    border: none !important;
    margin: 0 !important;
    bottom: -6px !important;
    transform: translateX(-50%);
    opacity: 0.95;
    background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.55583 7.73308L15.8549 0H0.145264L7.15407 7.73308C7.54115 8.08897 8.16874 8.08897 8.55583 7.73308Z' fill='black'/%3E%3C/svg%3E%0A")
      center/100% 100% no-repeat;
  }

  &.place-left,
  &.place-right,
  &.place-bottom {
    &:after {
      display: none;
    }
  }
}

a.unstyled {
  color: inherit;
  text-decoration: inherit;
}

.closeButton {
  width: 12px;
  height: 12px;
  opacity: 0.25;
  transition: opacity $trans;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.98924 6L11.9638 0.241104C12.0472 0.145399 11.9771 0 11.8482 0H10.336C10.2469 0 10.1616 0.0386502 10.1029 0.104908L6 4.85521L1.89714 0.104908C1.84028 0.0386502 1.755 0 1.66404 0H0.151759C0.022893 0 -0.0472254 0.145399 0.0361585 0.241104L5.01076 6L0.0361585 11.7589C0.0174797 11.7802 0.00549658 11.8063 0.00163129 11.8341C-0.00223399 11.8618 0.00218092 11.8901 0.0143523 11.9154C0.0265238 11.9408 0.0459405 11.9623 0.0702967 11.9773C0.094653 11.9923 0.122926 12.0002 0.151759 12H1.66404C1.75311 12 1.83839 11.9613 1.89714 11.8951L6 7.14478L10.1029 11.8951C10.1597 11.9613 10.245 12 10.336 12H11.8482C11.9771 12 12.0472 11.8546 11.9638 11.7589L6.98924 6Z' fill='white'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;

  &:hover {
    opacity: 1;
  }
}

.avatarImg {
  border-radius: 50%;
}
