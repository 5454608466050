@import './variables';

.chat {
  position: fixed;
  padding: 112px 21px 16px 16px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 360px;
  display: flex;
  flex-direction: column;
  background: url("data:image/svg+xml,%3Csvg width='366' height='1080' viewBox='0 0 366 1080' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_2_37327)'%3E%3Cpath d='M0 0H360L364.5 158L360 174L355.5 287.5L360 298.5L357.5 391L360 522L364 532L360 594.5V656.25L357.5 677L360 718L357.5 770.5L360 824L363.5 919.5L357.5 929V1044L360 1080H0V0Z' fill='%23181726'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_2_37327' x='0' y='0' width='365.5' height='1080' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2_37327'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2_37327' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
    right top / 366px auto repeat-y;
}

.tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.triggers {
  display: grid;
  flex-shrink: 0;
  margin-bottom: 1px;
  grid-template-columns: repeat(2, 1fr);
  height: 48px;
  margin: 0;
  padding: 8px;
  background: url("data:image/svg+xml,%3Csvg width='328' height='48' viewBox='0 0 328 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 30C0 39.9411 5.37259 48 12 48H15V42L21 48H316C322.627 48 328 39.9411 328 30V22.5L321.5 27L321 40.5L319.5 24.75L327.128 11.25C325.348 4.65338 321.036 0 316 0H19L18 11.25L13.5 0H12C5.37258 0 0 8.05888 0 18L5.5 27L0 29.25V30Z' fill='%23212031'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;
}

.triggersInvisible {
  display: none;
}

.triggersBtn {
  list-style: none;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 133%;
  text-transform: uppercase;
  color: rgba(white, 0.5);
  border-radius: 6px;
  cursor: pointer;
  transition: color $trans, background $trans;

  &:hover {
    color: $white;
    filter: none;
  }

  &[aria-selected='true'] {
    color: $white;
    background: #2a2a3c;
  }
}

.triggersBtnNotify {
  padding-left: 8px;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $red;
    transform: translate(-2px, -7px);
  }
}

.react-tabs__tab--selected {
  color: $white;
  background: #2a2a3c;
}

.panel {
  flex-grow: 1;
  flex-direction: column;
  display: none;
  position: relative;
}

.panelActive {
  display: flex;
}

.messages {
  flex-grow: 1;
  position: relative;
}

.messagesScroller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden auto;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    display: none;
  }

  > *:not(:first-child) {
    margin-bottom: 16px;
  }

  > *:last-child {
    margin-top: 100px;
  }
}

.chatUsersScroller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  > *:first-child {
    margin-top: 16px;
  }
}

.messagesLastMessage {
  color: $grey;
}

.chatUser {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;

  position: relative;
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  background: url("data:image/svg+xml,%3Csvg width='5' height='7' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 -5.3662e-08L0 6.5L-7.75117e-08 0L4.5 -5.3662e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 10px / 5px 7px no-repeat,
    url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 11.5L1.74846e-07 7.5L5 6.5L14 -3.41715e-07L6.5 8.5L0 11.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 calc(100% - 14px) / 14px 12px no-repeat,
    url("data:image/svg+xml,%3Csvg width='4' height='11' viewBox='0 0 4 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 11L-5.3662e-08 6.5L-1.31174e-07 0L4 11Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 calc(50% - 8px) / 4px 11px no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 8L0 0H6.5V8Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      44px 0 / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 6.5L0 0H9.5L2 6.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      center 0 / 10px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 9L0.5 0H4.5V9Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 56px) 0 / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='19' height='8' viewBox='0 0 19 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 0L19 4L13.5 4L0.5 8L11 1.5L19 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right 24px / 19px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.49061e-07 -5.96244e-08L5 12.5L5 6L1.49061e-07 -5.96244e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right calc(100% - 13px) / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0.5L0.5 5H6.5L2.5 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 30px) bottom / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0L0.5 8H10L12 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(50% + 25px) bottom / 12px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.5L3.5 9H10L0 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      62px bottom / 10px auto no-repeat,
    #212031;
}

.messageUsername {
  cursor: pointer;
}

.chatUserContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.chatUserName {
  margin-left: 8px;
}

.duelLink {
  font-size: 11px;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  background-color: #000;
  cursor: pointer;
}

.message {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.badges {
  margin-left: 8px;
  display: inline-flex;
  gap: 4px;
}

.messageAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  margin-right: 6px;
  border: 2px solid #3f3d51;
  background: #212031;
  cursor: pointer;

  img {
    border-radius: 50%;
  }
}



.messageContent {
  position: relative;
  width: calc(100% - 46px);
  padding: 10px 16px;
  border-radius: 12px;
  background: url("data:image/svg+xml,%3Csvg width='5' height='7' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 -5.3662e-08L0 6.5L-7.75117e-08 0L4.5 -5.3662e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 10px / 5px 7px no-repeat,
    url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 11.5L1.74846e-07 7.5L5 6.5L14 -3.41715e-07L6.5 8.5L0 11.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 calc(100% - 14px) / 14px 12px no-repeat,
    url("data:image/svg+xml,%3Csvg width='4' height='11' viewBox='0 0 4 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 11L-5.3662e-08 6.5L-1.31174e-07 0L4 11Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 calc(50% - 8px) / 4px 11px no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 8L0 0H6.5V8Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      44px 0 / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 6.5L0 0H9.5L2 6.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      center 0 / 10px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 9L0.5 0H4.5V9Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 56px) 0 / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='19' height='8' viewBox='0 0 19 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 0L19 4L13.5 4L0.5 8L11 1.5L19 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right 24px / 19px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.49061e-07 -5.96244e-08L5 12.5L5 6L1.49061e-07 -5.96244e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right calc(100% - 13px) / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0.5L0.5 5H6.5L2.5 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 30px) bottom / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0L0.5 8H10L12 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(50% + 25px) bottom / 12px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.5L3.5 9H10L0 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      62px bottom / 10px auto no-repeat,
    #212031;
}

.notification {
  padding: 12px 16px;
  background: url("data:image/svg+xml,%3Csvg width='5' height='7' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 -5.3662e-08L0 6.5L-7.75117e-08 0L4.5 -5.3662e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 10px / 5px 7px no-repeat,
    url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 11.5L1.74846e-07 7.5L5 6.5L14 -3.41715e-07L6.5 8.5L0 11.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      0 calc(100% - 14px) / 14px 12px no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 8L0 0H6.5V8Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      44px 0 / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 6.5L0 0H9.5L2 6.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      center 0 / 10px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 9L0.5 0H4.5V9Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 56px) 0 / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='19' height='8' viewBox='0 0 19 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 0L19 4L13.5 4L0.5 8L11 1.5L19 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right 24px / 19px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.49061e-07 -5.96244e-08L5 12.5L5 6L1.49061e-07 -5.96244e-08Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      right calc(100% - 13px) / 5px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 0.5L0.5 5H6.5L2.5 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(100% - 30px) bottom / 7px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0L0.5 8H10L12 0Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      calc(50% + 25px) bottom / 12px auto no-repeat,
    url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.5L3.5 9H10L0 0.5Z' fill='%23181726'/%3E%3C/svg%3E%0A")
      62px bottom / 10px auto no-repeat,
    #212031;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 170%;
  color: $grey;
  border-radius: 12px;

  span {
    vertical-align: middle;
    margin-right: 6px !important;
    transform: translateY(-2px);
  }

  b {
    font-weight: bold;
    color: $white;
  }
}

.messageTop {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 145%;
  color: $white;
  margin-bottom: 6px;
  padding-right: 50px;
}

.messageTime {
  top: 12px;
  right: 16px;
  position: absolute;
  font-size: 1rem;
  line-height: 150%;
  text-align: right;
  color: $grey;
}

.messageText {
  font-size: 1.2rem;
  line-height: 150%;
  color: $grey;

  a {
    cursor: pointer;
    color: $white;
    text-decoration: none;
    font-weight: bold;
  }
}

.info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 145px;
  padding: 16px 0;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(#181726, 0.9) 30.56%,
    rgba(24, 23, 38, 0) 100%
  );
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

.infoBtn {
  height: 28px;
  padding: 0px 8px;
  background: #323146;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 100%;
  text-transform: uppercase;
  color: $grey;
  transition: background $trans;

  &:hover {
    filter: none;
    background: rgba(#323146, 0.8);
  }

  svg {
    margin-right: 4px;
    flex-shrink: 0;
  }
}

.infoBtnGreen {
  color: $green;
}

.form {
  padding: 8px 16px;
  height: 48px;
  margin-top: 16px;
  flex-shrink: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(2, 16px);
  gap: 16px;
  background: url("data:image/svg+xml,%3Csvg width='328' height='48' viewBox='0 0 328 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 36C0 42.6274 5.37259 48 12 48H72.5L71 41L77 48H122.5L129.5 41V36L132.5 41L129.5 48H186.5V41L194.5 48H219L225 44.5V48H316C322.627 48 328 42.6274 328 36V19.5L322 28L311 33.5L320.5 26L327.128 7.5C325.348 3.10225 321.036 0 316 0H243L236 7.5L237.5 0H210L208.5 6L203 0H169.5L172 7.5L157.5 0H108.5L103 7.5V0H51L44 4L39 0H12C5.37258 0 0 5.37258 0 12V28L5 26L0 33.5V36Z' fill='%23212031'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;
}

.formInput {
  min-width: 1px;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: none;
  background: none;
  font-size: 1.4rem;
  line-height: 150%;
  color: $white;

  &::placeholder {
    color: $grey;
  }

  &:focus {
    outline: none;
  }
}

.users {
  position: absolute;
  z-index: 10;
  top: -8px;
  left: 0;
  right: 0;
  max-height: calc(100vh - 192px);
  flex-direction: column;
  background: #212031;
  border: 1px solid #29283a;
  border-radius: 12px;
  padding-bottom: 16px;
}

.usersScroller {
  height: 500px;
  max-height: 100%;
  overflow: hidden scroll;
  padding-right: 6px;
  margin-right: 6px;
  width: calc(100% - 6px);

  &::-webkit-scrollbar {
    width: 4px;

    &-thumb {
      background: #3d4254;
      border-radius: 99px;
    }

    &-track {
      background: rgba(#7e818a, 0.1);
      border-radius: 99px;
    }
  }
}

.usersTitle {
  font-weight: 600;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: $gold;
}

.usersHeading {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #29283a;
  margin-bottom: 9px;
}

.usersClose {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.98924 6L11.9638 0.241104C12.0472 0.145399 11.9771 0 11.8482 0H10.336C10.2469 0 10.1616 0.0386502 10.1029 0.104908L6 4.85521L1.89714 0.104908C1.84028 0.0386502 1.755 0 1.66404 0H0.151759C0.022893 0 -0.0472254 0.145399 0.0361585 0.241104L5.01076 6L0.0361585 11.7589C0.0174797 11.7802 0.00549658 11.8063 0.0016313 11.8341C-0.00223399 11.8618 0.00218093 11.8901 0.0143524 11.9154C0.0265238 11.9408 0.0459405 11.9623 0.0702967 11.9773C0.094653 11.9923 0.122926 12.0002 0.151759 12H1.66404C1.75311 12 1.83839 11.9613 1.89714 11.8951L6 7.14478L10.1029 11.8951C10.1597 11.9613 10.245 12 10.336 12H11.8482C11.9771 12 12.0472 11.8546 11.9638 11.7589L6.98924 6Z' fill='white'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;
  opacity: 0.25;
  transition: opacity $trans;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.messageClose {
  bottom: 12px;
  right: 16px;
  position: absolute;
  font-size: 14px;

  color: #ef4444;
  opacity: 0.5;
  transition: opacity $trans;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.usersList {
  color: $white;
  fill: $white;
  padding-bottom: 4px;

  &:first-child .usersListHeading:before {
    display: none;
  }
}

.isAdmins {
  fill: #ef4444;
  color: #ef4444;
}

.isModerators {
  fill: #fdba74;
  color: #fdba74;
}

.usersListHeading {
  padding: 10px 16px 4px;
  position: relative;
  display: flex;
  align-items: center;
  color: inherit;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;

  svg {
    margin-right: 6px;
    fill: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    right: 85px;
    background: #29283a;
  }
}

.usersListItem {
  padding: 10px 8px 10px 20px;
  display: flex;
  align-items: center;
}

.usersListItemAvatar {
  flex-shrink: 0;
  margin-right: 8px;
}

.usersListItemName {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
}

.usersListItemBtn {
  width: 20px;
  height: 20px;
  color: 'white';
  margin-left: auto;
  flex-shrink: 0;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00001 6.31579C7.00001 5.73684 7.45001 5.26316 8.00001 5.26316H17V1.05263C17 0.473684 16.55 0 16 0H1C0.45 0 0 0.473684 0 1.05263V12.6316C0 13.2105 0.45 13.6842 1 13.6842H2V16.8421L5 13.6842H7.00001V6.31579Z' fill='white'/%3E%3Cpath d='M19 7.36876H10C9.45 7.36876 9 7.84244 9 8.42139V15.7898C9 16.3688 9.45 16.8424 10 16.8424H15L18 20.0003V16.8424H19C19.55 16.8424 20 16.3688 20 15.7898V8.42139C20 7.84244 19.55 7.36876 19 7.36876Z' fill='white'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;
  opacity: 0.25;
  transition: opacity $trans;
  will-change: opacity;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.dialog {
  height: 56px;
  background: url("data:image/svg+xml,%3Csvg width='328' height='56' viewBox='0 0 328 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 44C0 50.6274 5.37259 56 12 56H15V52L21 56H316C322.627 56 328 50.6274 328 44V15L321.5 18L321 27L319.5 16.5L327.128 7.5C325.348 3.10225 321.036 0 316 0H19L18 7.5L13.5 0H12C5.37258 0 0 5.37258 0 12V35.5L5.5 42L0 43.5V44Z' fill='%23212031'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 32px 0 10px;
  position: relative;
  flex-shrink: 0;
  margin-bottom: 0 !important;
  cursor: pointer;
  transition: opacity $trans;

  &:hover {
    opacity: 0.9;
  }

  + .dialog {
    margin-top: 8px;
  }
}

.dialogContent {
  background: none;
  padding: 0;
  position: static;

  .messageText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .messageTop {
    margin-bottom: 2px;
  }

  .messageTime {
    right: 8px;
    top: 8px;
  }
}

.dialogNtf {
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  background: #ff002e;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: $white;
}

.messageContentHighlighted {
  box-shadow: 0 0 4px white;
  border-radius: 4px;
}

.empty {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}