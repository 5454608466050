@import './variables';

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: 102px;
  background: url("data:image/svg+xml,%3Csvg width='1920' height='102' viewBox='0 0 1920 102' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_2_44214)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1920 0H0V96H50.5L61.5 90L69 96L229.5 92L242.5 84V96H379L377.5 84L386.5 101L548.5 96L555.5 84L568.5 94L732 96L726.5 84L748.5 92L919 96L927 88L933 100L1051.5 96L1048 88L1058.5 96L1159.5 93L1168 88L1170.5 96H1272L1286.5 88L1289 96H1372.5L1386.5 88L1397.5 90L1533 96V88L1547.5 96H1637.5L1648 88L1650.5 100L1811 96L1818 88L1824.5 96H1920V0Z' fill='url(%23paint0_linear_2_44214)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_2_44214' x='0' y='0' width='1920' height='102' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2_44214'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2_44214' result='shape'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_2_44214' x1='960.221' y1='-0.0832574' x2='960.221' y2='95.9168' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23212131'/%3E%3Cstop offset='1' stop-color='%231D1C2C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
    center top / 1920px auto repeat-x;
  padding: 16px 32px;
  display: flex;
  align-items: center;
}

.logo {
  width: 296px;
  height: 62px;
  flex-shrink: 0;
  margin-right: 64px;
  transform: translateY(-8px);
}

.nav {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 48px;
  }

  + * {
    margin-left: auto;
  }
}

.navLink {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;
  height: 24px;
  text-transform: uppercase;
  color: rgba(white, 0.25);
  text-decoration: none;
  transition: color $trans;

  svg {
    margin-right: 8px;
    width: 20px;
    flex-shrink: 0;
    fill: rgba(white, 0.25);
    transition: fill $trans;
  }

  &:hover {
    color: $white;

    svg {
      fill: $white;
    }
  }
}

.navLinkActive {
  position: relative;
  color: $white;

  svg {
    fill: $white;
  }

  &:before {
    content: '';
    position: absolute;
    width: 84px;
    height: 5px;
    left: 50%;
    top: -39px;
    transform: translateX(-50%);
    background: url("data:image/svg+xml,%3Csvg width='84' height='5' viewBox='0 0 84 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='84' height='1' rx='0.5' fill='url(%23paint0_linear_2_44215)'/%3E%3Crect opacity='0.2' x='0.25' y='4.25' width='34.5' height='0.5' rx='0.25' stroke='url(%23paint1_linear_2_44215)' strokeWidth='0.5' stroke-dasharray='2 2'/%3E%3Crect opacity='0.2' x='-0.25' y='0.25' width='34.5' height='0.5' rx='0.25' transform='matrix(-1 0 0 1 83.5 4)' stroke='url(%23paint2_linear_2_44215)' strokeWidth='0.5' stroke-dasharray='2 2'/%3E%3Cpath d='M42 4L35 1H49L42 4Z' fill='%236BC663'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2_44215' x1='0' y1='0.998657' x2='84' y2='0.998657' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236BC663' stop-opacity='0'/%3E%3Cstop offset='0.494792' stop-color='%236BC663'/%3E%3Cstop offset='1' stop-color='%236BC663' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_2_44215' x1='0' y1='4.99866' x2='35' y2='4.99866' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236BC663' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23F2D879'/%3E%3Cstop offset='1' stop-color='%236BC663'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear_2_44215' x1='0' y1='0.998657' x2='35' y2='0.998657' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236BC663' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%236BC663'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
      center center / 100% 100% no-repeat;
  }
}

.wallet {
  display: flex;
  align-items: center;

  + * {
    margin-left: 32px;
  }
}

.walletAmount {
  padding: 12px;
  background: linear-gradient(90deg, #282839 0%, rgba(30, 30, 45, 0) 100%);
  border-radius: 8px 0px 0px 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: $gold;
  display: grid;
  align-items: center;
  grid-template-columns: 18px auto;
  gap: 8px;
  margin-right: 8px;
}

.profile {
  position: relative;
  padding: 2px 0 0 44px;
  min-height: 32px;
  text-decoration: none;
}

.profileAvatar {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  left: 0;
  top: 0;
  background: #2d2c3f;
}

.profileNickname {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 114%;
  color: $white;
}

.profileWallet {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 133%;
  color: $secondary;
}

.auth {
  height: 80px;
  padding: 0px 24px 0px 41px;
  background: linear-gradient(270deg, #171627 0%, rgba(23, 22, 39, 0) 100%);
  border-radius: 8px;
}
