@import './variables';

.userModal {
  width: 980px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroSelectModal {
  width: 980px !important;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroViewModal {
  width: 280px !important;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userModalAdminModerator {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  &Btn {
    width: 50px;
  }
}

.userModalCurrentUserActions {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.userModalAvatar {
  width: 94px;
  height: 94px;
  border: 4px solid #3f3d51;
  background: #212031;
  border-radius: 50%;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.userModalNickname {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 133%;
  text-align: center;
  color: $white;
}

.userModalRole {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 170%;
  text-align: center;
  color: $grey;
}

.userModalBadges {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    width: 496px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      90deg,
      rgba(15, 14, 24, 0) 0%,
      #0f0e18 50%,
      rgba(15, 14, 24, 0) 100%
    );
  }
}

.badgeNotIncluded {
  opacity: 0.5;
}

.badgeAdmin:not(.badgeDisabled) {
  cursor: pointer;
}

.userModalBadgesChat {
  color: $white;
  margin-top: -21px;
  margin-bottom: 25px;
}

.userModalBadge {
  position: relative;
  z-index: 1;
  width: 44px;
  height: 64px;
  padding: 10px 5px 25px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: url("data:image/svg+xml,%3Csvg width='44' height='64' viewBox='0 0 44 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M42.7113 45.1635L33 54.8479V49.5L34.5 42.0608L29.5 52.5L30.3406 57.5L25.1113 62.7148C23.393 64.4284 20.607 64.4284 18.8887 62.7148L14.5 58.3382L13 47L11 54.8479L1.28873 45.1635C0.463569 44.3406 0 43.2246 0 42.0608V26L3 22.5L0 13.5V0H44V27L40 25C40.4038 26.3333 42.969 31.7 44 32.5V42.0608C44 43.2246 43.5364 44.3406 42.7113 45.1635Z' fill='%23252438'/%3E%3C/svg%3E%0A")
    center center / 100% 100% no-repeat;

  + * {
    margin-left: 8px;
  }
}

.badge svg {
  filter: drop-shadow(0px 0px 16px #fff);
}

.userModalProgress {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  width: 100%;
}

.progressText {
  margin-top: -20px;
  text-align: center;
  color: $white;
  font-size: 1.2rem;
  font-weight: bold;
}

.userModalProgressLvl {
  flex-shrink: 0;
  min-width: 32px;
  height: 32px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #212031;
  border: 2px solid #3f3d51;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
  text-align: center;
  color: $white;
  position: relative;
  z-index: 2;
  padding: 3px 0 0;
  padding-left: 4px;
  padding-right: 4px;
}

.userModalProgressLine {
  flex: 1;
  height: 16px;
  border-radius: 6px;
  position: relative;
  background: linear-gradient(180deg, #1a1928 50%, #1e1d2e 100%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 50%;
    right: 0;
    background: linear-gradient(180deg, #201f30 0%, #242335 100%);
    border-radius: 6px 6px 0px 0px;
  }
}

.userModalProgressLineState {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    270deg,
    rgba(#f8c309, 0.5) 0%,
    rgba(248, 195, 9, 0) 100%
  );
  border-radius: 6px 0 0 6px;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 24px;
    background: #f7c309;
  }
}

.userModalTip {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
}

.userModalTipSelect {
  grid-column: 1/-1;
}

.userModalTipSelectInputGold {
  background: url('../public/gold_coin.png') right 14px center / 18px 18px
      no-repeat,
    radial-gradient(50% 100% at 50% 100%, #28283d 0%, #202030 100%) no-repeat !important;
}

.userModalTipSelectInputJewel {
  background: url('../public/jewel_coin.svg') right 14px center / 18px 18px
      no-repeat,
    radial-gradient(50% 100% at 50% 100%, #28283d 0%, #202030 100%) no-repeat !important;
}

.userModalSubtitle {
  margin: 24px 0 18px;
}

.userModalStats {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  height: 64px;
  background: url("data:image/svg+xml,%3Csvg width='608' height='64' viewBox='0 0 608 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H12C5.37258 0 0 5.37258 0 12V17L4.74724 26.9692C4.91104 27.3132 5.18508 27.5925 5.52583 27.7629C7.13857 28.5693 6.56476 31 4.76166 31H4.65178C4.2316 31 3.8257 30.8475 3.50949 30.5708L0 27.5V52C0 58.6274 5.37258 64 12 64H58L58.7147 62.8883C59.8243 61.1622 62.5 61.9481 62.5 64H152.5C152.5 60.6075 156.807 59.1535 158.863 61.852L160.5 64H296.5C295.899 60.6344 300.279 58.7905 302.266 61.5725L304 64H395.5C395.5 63.4424 396.22 60.6324 397.157 57.215C397.488 56.0117 399.197 56.356 399.049 57.5949C399.017 57.8549 399.088 58.1169 399.245 58.3264L403.5 64H589.5C589.165 61.4857 592.197 59.9131 594.13 61.5554C595.762 62.9417 597.132 64 597.5 64C603.299 64 608 59.299 608 53.5V28.5C604.773 25.2734 604.176 20.2567 606.556 16.363L608 14V12C608 5.37258 602.627 0 596 0H541C539.045 5.8637 531.885 8.04032 526.998 4.25655L521.5 0H362.5L359.235 5.59633C359.081 5.8607 359 6.16127 359 6.46733C359 8.39178 356.329 8.8762 355.653 7.07428L353 0H268C266.622 2.75661 262.824 3.09903 260.975 0.633436L260.5 0H178L184.582 5.642C184.841 5.86371 184.963 6.20605 184.902 6.54123L184.877 6.67566C184.671 7.81003 183 7.65936 183 6.5064C183 6.19003 182.842 5.89459 182.579 5.7191L174 0H100.5L98.5219 2.24788C96.9259 4.06149 94.027 2.21126 95 0H26C25.186 2.44194 21.9196 2.87938 20.4918 0.737654L20 0Z' fill='%23212031'/%3E%3C/svg%3E%0A")
    center center / cover no-repeat;

  + .userModalStats {
    margin-top: 16px;
  }
}

.userModalStatsItem {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 133%;
  color: $grey;

  b {
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 100%;
    color: $gold;

    > span {
      vertical-align: middle;
      margin-right: 6px !important;
      transform: translateY(-2px);
    }
  }
}

.rulesModalTitle {
  text-align: left;
  margin-bottom: 24px;
}

.rulesModalText {
  font-size: 1.6rem;
  line-height: 200%;
  color: $grey;
}

.fairModal {
  display: grid;
  width: 1086px;
  grid-template-columns: 507fr 579fr;
  padding: 0;
}

.fairModalRight {
  padding: 26px 36px 18px 26px;
}

.fairModalLeft {
  background: #13131f;
  padding: 185px 40px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px 27px;
  align-content: flex-start;
  border-radius: 12px 0 0 12px;
}

.fairModalBlockTitle {
  margin-bottom: 4px;
  font-size: 1.8rem;
}

.fairModalText {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 155%;
  color: #b5b5cd;
}

.fairModalTitle {
  margin: 0 0 11px;
  text-align: left;
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
    margin-right: 6px;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }
}

.fairModalInput {
  + .fairModalInput {
    margin-top: 16px;
  }
  input {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.25'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7646 7.42501V14.925C18.7646 15.7534 18.0931 16.425 17.2646 16.425C16.8504 16.425 16.5146 16.0892 16.5146 15.675V10.68C16.5151 10.4514 16.4231 10.2323 16.2596 10.0725L13.6496 7.49251C13.1842 7.01959 12.5506 6.74998 11.8871 6.74251H9.01465C8.60043 6.74251 8.26465 6.40673 8.26465 5.99251V5.15251C8.26464 4.58033 8.49245 4.0317 8.89775 3.62781C9.30305 3.22392 9.85247 2.99802 10.4246 3.00001H14.4071C14.9703 3.00569 15.5088 3.23187 15.9071 3.63001L18.1571 5.88001C18.5574 6.29352 18.776 6.84965 18.7646 7.42501ZM15.135 10.4249L12.885 8.17492C12.4876 7.77541 11.9485 7.54899 11.385 7.54492H7.4025C6.21249 7.54905 5.24999 8.51491 5.25 9.70492V18.7874C5.25 19.9804 6.21706 20.9474 7.41 20.9474H13.6275C14.8116 20.9351 15.7651 19.9716 15.765 18.7874V11.9249C15.7708 11.3587 15.5556 10.8125 15.165 10.4024L15.135 10.4249Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 24px 24px;
    background-position: right 16px center;
    background-repeat: no-repeat;
    transition: background $trans;
    cursor: pointer;
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7646 7.42501V14.925C18.7646 15.7534 18.0931 16.425 17.2646 16.425C16.8504 16.425 16.5146 16.0892 16.5146 15.675V10.68C16.5151 10.4514 16.4231 10.2323 16.2596 10.0725L13.6496 7.49251C13.1842 7.01959 12.5506 6.74998 11.8871 6.74251H9.01465C8.60043 6.74251 8.26465 6.40673 8.26465 5.99251V5.15251C8.26464 4.58033 8.49245 4.0317 8.89775 3.62781C9.30305 3.22392 9.85247 2.99802 10.4246 3.00001H14.4071C14.9703 3.00569 15.5088 3.23187 15.9071 3.63001L18.1571 5.88001C18.5574 6.29352 18.776 6.84965 18.7646 7.42501ZM15.135 10.4249L12.885 8.17492C12.4876 7.77541 11.9485 7.54899 11.385 7.54492H7.4025C6.21249 7.54905 5.24999 8.51491 5.25 9.70492V18.7874C5.25 19.9804 6.21706 20.9474 7.41 20.9474H13.6275C14.8116 20.9351 15.7651 19.9716 15.765 18.7874V11.9249C15.7708 11.3587 15.5556 10.8125 15.165 10.4024L15.135 10.4249Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
}

.fairModalBlock {
  display: flex;
  flex-direction: column;
  img {
    pointer-events: none;
  }
}

.fairModalBlockText {
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  padding: 0px 24px;
  height: 32px;
  background: #212031;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  color: $white;
}

.fairModalRow {
  display: flex;
  justify-content: center;
  grid-column: 1/-1;
  > * + * {
    margin-left: 18px;
  }
}

.fairModalBtn {
  width: 100%;
  margin: 24px 0 0;
}

.fairModalNote {
  margin-top: 16px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 150%;
  color: $grey;

  b {
    font-weight: bold;
    color: #7cff8c;
  }
}

.fairModalTooltip {
  transform: translateY(-2px);
  vertical-align: middle;
  margin-left: 8px;
  opacity: 0.25;
  transition: opacity $trans;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.authModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authModalText {
  margin: 16px 0 24px;
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
  line-height: 150%;
  color: #b5b5cd;
}

.modalText {
  margin: 16px 0 24px;
  text-align: center;
  width: 100%;
  font-size: 1.8rem;
  line-height: 150%;
  color: #b5b5cd;
}

.authModalBtn {
  width: 100%;
}

.fairModalBlockIll1 {
  position: relative;
  > img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% - 30px);
  }
}

.fairModalBlockIll2 {
  position: relative;
  > img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% - 55px);
  }
}

.fairModalBlockIll3 {
  position: relative;
  padding-top: 170px;
  > img {
    position: absolute;
    left: 50%;
    transform: translateX(calc(-50% + 5px));
    bottom: 2px;
    pointer-events: none;
  }
}

.fairModalArrow {
  grid-column: 1/-1;
  padding: 28px 0 75px;
  background: url("data:image/svg+xml,%3Csvg width='87' height='128' viewBox='0 0 87 128' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M87.0001 13.7259V-0.000972777L43.5991 35.3254L0.399903 -0.000976562L0.399902 13.7259L43.5991 49.0523L87.0001 13.7259Z' fill='url(%23paint0_linear_216_74370)'/%3E%3Cpath d='M87.0001 39.9683V26.2415L43.5991 61.5679L0.399903 26.2415L0.399902 39.9683L43.5991 75.2948L87.0001 39.9683Z' fill='url(%23paint1_linear_216_74370)'/%3E%3Cpath d='M87.0001 66.4119V52.6851L43.5991 87.8096L0.399903 52.6851L0.399902 66.4119L43.5991 101.536L87.0001 66.4119Z' fill='url(%23paint2_linear_216_74370)'/%3E%3Cpath d='M87.0001 92.6544V78.9276L43.5991 114.254L0.399903 78.9276L0.399902 92.6544L43.5991 127.981L87.0001 92.6544Z' fill='url(%23paint3_linear_216_74370)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_216_74370' x1='43.7' y1='-0.000976563' x2='43.7' y2='127.981' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2313131F' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23003D39'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_216_74370' x1='43.7' y1='-0.000976563' x2='43.7' y2='127.981' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2313131F' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23003D39'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear_216_74370' x1='43.7' y1='-0.000976563' x2='43.7' y2='127.981' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2313131F' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23003D39'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint3_linear_216_74370' x1='43.7' y1='-0.000976563' x2='43.7' y2='127.981' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2313131F' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23003D39'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
    center top / 87px auto no-repeat;
  .heading {
    font-size: 1.8rem;
    font-weight: 600;
  }
}
